import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

import {
  cancelSubscriptionRequest,
  productMasterIndex,
  returnToCach,
  returnToCard,
  userRelatedSubscriptionChangeRequestIndex,
  limitationsOfSubscriptionChanges,
  limitationsOfMembershipChanges,
} from '../Apis/apiOfManaged';
import OnOffByScroll from '../Animation/onOffByScroll';
import Payment from './payment';
import Footer from '../Footer/footer';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../auth';
// import { localStorageArrayHandler } from '../CommonFunction/managePermission';
import various from '../General/various';
import MembershipOption from '../PageComponents/membershipOption';
import { Spinner } from '../Spinner';
import StandardDialog from '../Dialog/standardDialog';
import { MainTheme } from '../Theme/theme';

const useStyles = makeStyles((theme) => ({
  option: {
    '& > div': {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      lineHeight: '56px',
      '& > h1': {
        width: '100%',
        textAlign: 'center',
      },
      '& > p': {
        margin: '0px 24px 80px 24px',
        width: '100%',
        fontSize: '1rem',
        lineHeight: '24px',
        textAlign: 'start',
        padding: '0px 32px',
      },
      '& > #attention': {
        fontSize: '0.8rem',
        color: 'grey',
        padding: '0px 32px',
        textAlign: 'center',
      },
    },
    '& > form': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      frexWrap: 'wrap',

      '& #cassette': {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
      },
    },
  },
  anounce: {
    margin: '20px',
    color: 'grey',
    fontSize: '0.8rem',
    '& > span': {
      cursor: 'pointer',
      textDecoration: 'underline',
      color: theme.palette.primary.main,
    },
  },
  methodOfCash: {
    width: '100%',
    marginTop: '64px',
    textAlign: 'start',
    '& > span': {
      padding: '32px',
      fontSize: '1rem',
    },
    '& > button': {
      fontFamily: theme.typography.fontFamily,
      padding: '4px 8px',
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      cursor: 'pointer',
      '&:disabled': {
        // backgroundColor: 'grey',
        cursor: 'not-allowed',
      },
    },
    '& > p': {
      fontSize: '0.8rem',
      color: 'grey',
      padding: '0px 32px',
    },
    '@media screen and (max-width:600px)': {
      display: 'flex',
      flexDirection: 'column',
      '& > button': {
        width: '50%',
        margin: '0 auto',
      },
    },
  },
  methodOfCard: {
    width: '100%',
    marginTop: '48px',
    display: 'flex',
    justifyContent: 'center',
    '& > button': {
      width: '90%',
      height: '40px',
      marginTop: '24px',
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      border: 'none',
      padding: '4px 8px',
      // backgroundColor: '#9079b6',
      backgroundColor: 'darkolivegreen',
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: '#fff',
      cursor: 'pointer',
      '&:disabled': {
        backgroundColor: 'grey',
        cursor: 'not-allowed',
      },
    },
  },
}));

const PaymentView = () => {
  const { isAuth } = useContext(AuthContext);
  // const permissions = localStorageArrayHandler('parse', various.permissionKey);

  const [products, setProducts] = useState([]);
  const [productType, setProductType] = useState(null);
  const [options, setOptions] = useState([]);
  const [productUuid, setProductUuid] = useState(null);
  const [isStripeCustomer, setIsStripeCustomer] = useState(false);
  const [isInitial, setIsInitial] = useState(true);
  const [methodOfCash, setMethodOfCash] = useState(false);
  const [changed, setChanged] = useState(false);
  const [shownPaymentDialog, setShownPaymentDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [changeRequest, setChangeRequest] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLimited, setIsLimited] = useState(false);
  const [isLimitedHalfYear, setIsLimitedHalfYear] = useState(false);
  const [nextUpdateDate, setNextUpdateDate] = useState();

  const classes = useStyles();

  const location = useLocation();
  const navigate = useNavigate();
  const theme = MainTheme();

  // console.log('state: ', location.state);
  // if (changeRequest) console.log(changeRequest[0]);
  // if (productType) console.log(productType);

  // Init
  const handleAuth = () => {
    navigate(various.general.login, { state: { previous: location.pathname } });
  };

  const handleApply = () => {
    navigate(various.general.contactForTraining, {
      state: { previous: location.pathname },
    });
  };

  useEffect(() => {
    productMasterIndex(setProducts);
  }, []);

  useEffect(() => {
    if (location?.state) {
      if (location.state?.customer_email && location.state?.customer_id) {
        setIsStripeCustomer(true);
      } else {
        setIsStripeCustomer(false);
      }

      if (location.state.isInitial) {
        setIsInitial(true);
      } else {
        setIsInitial(false);
      }

      if (location.state.user_email) {
        userRelatedSubscriptionChangeRequestIndex(
          setChangeRequest,
          location.state.user_email
        );

        // 定例会員の変更制限のチェック
        // 2024/4/24の会議で運用停止が決定 --> API側で処理をせずにリターンする
        async function checkLimitation() {
          const result = await limitationsOfSubscriptionChanges(
            {
              user_email: location.state.user_email,
            },
            setIsLimited
          );
          return result;
        }
        checkLimitation();
        // 半年制限のチェック
        async function checkLimitationOfHalfYear() {
          const result = await limitationsOfMembershipChanges(
            {
              user_email: location.state.user_email,
            },
            setIsLimitedHalfYear
          );
          return result;
        }
        checkLimitationOfHalfYear();
      }
    }
  }, [location]);

  useEffect(() => {
    if (
      changeRequest &&
      changeRequest.length > 0 &&
      changeRequest[0]?.status === 'pending'
    ) {
      setIsDisabled(true);
    }
  }, [changeRequest]);

  useEffect(() => {
    if (!isLimitedHalfYear) {
      const next_update_date =
        location.state?.member?.paymentmethodchangehistory?.next_update_date;
      if (next_update_date) {
        const rawDate = new Date(next_update_date);
        let localDate = rawDate.toLocaleDateString();
        const hours = rawDate.getHours();
        const minutes = rawDate.getMinutes();
        localDate = localDate + ' ' + hours + ':' + minutes;
        setNextUpdateDate(localDate);
      }
    }
  }, [isLimitedHalfYear, location]);

  // Main
  useEffect(() => {
    if (products.length > 0) {
      const filterProducts = products.filter((product) => {
        return (
          // 定例会員の商品
          (product.product_category === 'basic' &&
            product.product_type === 'subscription' &&
            product.is_active) ||
          // 回数会員の商品は便宜上１０００円に設定しているため、他の金額の商品は除外（回数会員登録の際に複数表示されてしまうため）
          (product.product_category === 'basic' &&
            product.is_active &&
            product.price === 1000)
        );
      });

      // 定例会員変更回数による制限による提供商品の制御
      let fixOptions;
      if (isLimited === true && isLimitedHalfYear === true) {
        fixOptions = filterProducts.map((product, index) => {
          // 商品情報のセット
          const label = product.name;
          const price = product.price;
          const description = product.description;
          if (
            product.metadata.membership &&
            product.product_type === 'payment' &&
            product.is_active
          ) {
            product.product_type = 'setup';
          }
          const value = product.product_type;
          const product_uuid = product.uuid;

          // 決済情報登録済み会員に対する表示商品の制御
          if (
            isStripeCustomer &&
            location.state?.customer_type === 'subscription'
          ) {
            if (product.product_type === 'subscription') {
              // 定例会員の場合、定例会員登録カセットは非表示
              return null;
            }
          } else if (
            isStripeCustomer &&
            (location.state?.customer_type === 'payment' ||
              location.state?.customer_type === 'setup') &&
            location.state?.stripeCustomer?.credit_cards?.length > 0
          ) {
            if (product.product_type === 'setup') {
              // 回数会員の場合、回数会員登録カセットは非表示
              return null;
            }
          }
          // 商品情報の表示
          return (
            <MembershipOption
              key={product.uuid}
              label={label}
              value={value}
              price={price}
              description={description}
              selectedValue={productType}
              productUuid={product_uuid}
              onChange={handleProductType}
            />
          );
        });
      } else {
        fixOptions = (
          <>
            <hr />
            <p
              style={{
                fontSize: '0.9rem',
                textAlign: 'start',
                margin: '0 32px',
              }}
            >
              【注意】
              <br />
              前回の変更からまだ半年が経過しておりません。変更は『
              {nextUpdateDate}』 以降に行なってください。
            </p>
            <hr />
          </>
        );
      }
      setOptions(fixOptions);
      setLoading(false);
      if (location.state.isInitial === false) {
        if (
          // location.state.customer_type !== 'setup' &&
          location.state.stripeCustomer?.by_stripe !== false
        ) {
          // 現金に戻すボタンの表示
          setMethodOfCash(true);
        }
      }
    }
  }, [
    products,
    productType,
    isInitial,
    isStripeCustomer,
    location,
    isLimited,
    nextUpdateDate,
    isLimitedHalfYear,
  ]);

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const handleProductType = (event) => {
    setProductType(event.target.value);
    setProductUuid(event.productUuid);
  };

  // 現金決済関連
  useEffect(() => {
    if (changed) {
      // 定例会員から現金扱いの回数会員への変更
      setLoading(false);
      alert('支払い方法を変更しました。');
      navigate(various.general.mypage, {
        state: { previous: location.pathname },
      });
    }
  }, [changed, location, navigate]);

  const handleClose = () => {
    setShownPaymentDialog(false);
    setLoading(false);
  };

  const showDialog = () => {
    setShownPaymentDialog(true);
  };

  const returntoCach = () => {
    setLoading(true);
    if (location.state?.customer_type === 'subscription') {
      // subscription契約の解約は、翌月1日に解約の予約 ---> この場合のみ「定例会員だが現金支払い」状態になる（1ヶ月未満の期間）
      const data = {
        user_email: location.state.user_email,
        change_type: '本人による変更',
        payment_method: 'cash',
        payment_type: 'setup',
      };
      cancelSubscriptionRequest(data, setChanged);
    } else {
      // それ以外は即時現金決済に戻す
      const data = {
        customer_id: location.state.customer_id,
        customer_email: location.state.customer_email,
      };
      returnToCach(data, setChanged);
    }
  };

  const returntoCardPayment = async () => {
    const data = {
      customer_id: location.state.customer_id,
    };
    await returnToCard(data, setChanged);
  };

  const gotoConstitution = () => {
    navigate(various.general.constitution, {
      state: { previous: location.pathname },
    });
  };

  return (
    <>
      <div style={{ margin: '80px' }}>
        <OnOffByScroll place="General" />
      </div>
      <div className={classes.option}>
        <div>
          {isInitial ? (
            <>
              <h1>会員区分の選択</h1>
              <p onClick={gotoConstitution}>
                刀禅では会員区分と決済方法が密接に連関しています。今後の稽古への参加状況を考慮してご選択ください。
                なお、（定例）{various.general_member}
                をご希望される方はカード決済をお願いしております。詳しくは
                <a
                  href={various.general.constitution}
                  style={{
                    textDecoration: 'underline',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    color: theme.palette.primary.main,
                  }}
                >
                  会則
                </a>
                をご確認ください。また、会員区分を選択後、その会員区分を変更は半年に一度だけ行うことができます。
              </p>
            </>
          ) : (
            <>
              <h1>決済方法の変更</h1>
              <p onClick={gotoConstitution}>
                決済方法の変更は会員区分の変更に他なりません。今後の稽古への参加状況を考慮して変更してください。
                なお、会員区分の変更は
                <span
                  style={{
                    fontWeight: 'bold',
                    fontSize: '1.2rem',
                  }}
                >
                  "半年に一度だけ"
                </span>
                行うことが可能です。十分ご注意ください。 詳しくは
                <a
                  href={various.general.constitution}
                  style={{
                    textDecoration: 'underline',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    color: theme.palette.primary.main,
                  }}
                >
                  会則
                </a>
                をご確認ください。
              </p>
            </>
          )}
          {!isStripeCustomer ? (
            <p id="attention">
              以下、いずれかの会員区分をご選択のうえ、「決済方法・会員区分の登録」ボタンを押してください。
            </p>
          ) : null}
        </div>
        <form onSubmit={handleSubmit}>
          {loading ? <Spinner /> : <div id="cassette">{options}</div>}
          <Payment
            isAuth={isAuth}
            selectedProductType={productType}
            selectedProductUuid={productUuid}
            customerId={location.state?.customer_id}
            customerEmail={location.state?.customer_email}
            userEmail={location.state.user_email}
            planFlag="membership"
            applyData={location.state?.apply_data}
            buttonName={
              isStripeCustomer
                ? '決済方法・会員区分の変更'
                : '決済方法・会員区分の登録'
            }
            customerType={location.state?.customer_type}
            disabled={isDisabled}
          />
        </form>
        {isDisabled ? (
          <p
            style={{
              fontSize: '0.8rem',
              color: 'grey',
              textAlign: 'start',
              width: '90%',
              margin: '0 auto',
              marginTop: '16px',
            }}
          >
            ※もうすでに、
            {new Date(changeRequest[0].execution_date).toLocaleDateString()}
            に会員種別を回数会員に変更することを予定しています。
            変更をキャンセルしたい場合は、来月1日以降に再度変更をお願いします。
          </p>
        ) : null}
      </div>

      {methodOfCash ? (
        <>
          <div className={classes.methodOfCash}>
            <span>現金決済に戻す場合は次のボタンからお進みください：</span>
            {loading ? (
              <Spinner />
            ) : (
              <button
                type="button"
                onClick={showDialog}
                // disabled={
                //   changeRequest && changeRequest.length > 0 ? true : false
                // }
              >
                現金決済に戻す
              </button>
            )}
            {location.state?.customer_type === 'subscription' ? (
              <p>
                ※定例会員で現金決済に戻した場合、翌月1日に自動で回数会員へと変更され、その後の変更は半年間行えません。ご注意ください。
              </p>
            ) : null}
            {/* {changeRequest && changeRequest.length > 0 ? (
              <p>
                ※現在
                {new Date(changeRequest[0].execution_date).toLocaleDateString()}
                に会員区分の変更を予定しています。現金決済に戻される場合はこの変更後に手続きをお願いします。
              </p>
            ) : null} */}
          </div>
          <StandardDialog
            open={shownPaymentDialog}
            handleClose={handleClose}
            text={{
              title: '現金決済に戻してもよろしいですか？',
              content:
                '寡少な運営のためカード決済を"切に"お願いしておりますが、以下のボタンから現金決済に戻すことができます。なお、カード決済にはMyPageからいつでも切り替えることができます。',
              agree: '現金決済にする',
              disagree: 'やはり止める',
            }}
            func={{ agreeFunc: returntoCach, disagreeFunc: handleClose }}
          />
        </>
      ) : location.state?.isInitial === false &&
        location.state?.stripeCustomer?.current_payment_method === 'cash' &&
        location.state?.stripeCustomer?.credit_cards &&
        location.state?.stripeCustomer?.credit_cards.length > 0 ? (
        <div className={classes.methodOfCard}>
          <button type="button" onClick={returntoCardPayment}>
            カード決済に戻す
          </button>
        </div>
      ) : null}
      {!isAuth ? (
        <>
          <div className={classes.anounce}>
            進むためには、
            <span onClick={handleAuth}>ログイン</span>
            が必要です。
            <br />
            まだ会員ではない方は、まずは
            <span onClick={handleApply}>体験稽古</span>
            へお申込みください。
            <br />
            ※現在開発中のため、ログインしても先には進めません。
          </div>
        </>
      ) : null}
      <Footer />
    </>
  );
};
export default PaymentView;
