import { makeStyles } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import OnOffByScroll from '../Animation/onOffByScroll';
import { AuthContext } from '../auth';
import { seminarAssistanceIndexSortedByMember } from '../Apis/apiOfManaged';
import Footer from '../Footer/footer';
import various from '../General/various';
import Clock from '../General/clock';
import { Spinner } from '../Spinner';
import BreadCrumbs from '../General/breadCrumbs';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    margin: '0px 0px 80px 0px',
    textAlign: 'start',
    '& > p': {
      marginLeft: '8px',
    },
    '& > p, & > ul': {
      fontSize: '0.8rem',
    },
  },
  container: {
    marginTop: '80px',
    fontSize: '0.8em',
    '& > table': {
      borderCollapse: 'collapse',
      '& > thead, & > tbody': {
        '& > tr': {
          borderBottom: '1px solid grey',
          '& > td': {
            height: '96px',
            textAlign: 'start',
            paddingRight: '32px',
            '& > a': {
              color: 'black',
              textDecoration: 'underline',
            },
            '& > button': {
              display: 'block',
              fontFamily: theme.typography.fontFamily,
              fontWeight: 'bold',
              borderRadius: '0.5em',
              border: 'none',
              padding: '4px 8px',
              backgroundColor: theme.palette.primary.main,
              boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
              color: '#fff',
              cursor: 'pointer',
            },
          },
          //   '& $img': {
          //     textAlign: 'center',
          //   },
        },
      },
    },
  },
}));

const MyPageSeminarUpdate = () => {
  const TIMEZONE = various.timezone;
  const { isAuth } = useContext(AuthContext);

  const [sa, setSa] = useState();
  const [seminars, setSeminars] = useState();
  const [uuid, setUuid] = useState();
  const [loading, setLoading] = useState(true);

  const location = useLocation();
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();
  const classes = useStyles();

  const CRUMBS = [
    { name: 'MyPage', path: '/mypage' },
    { name: 'ManageSeminar', path: '/mypage/seminar' },
  ];

  // if (location.state) console.log('location.state', location.state);

  // Init
  useEffect(() => {
    const query = searchParams.get('uuid');
    if (query) {
      setUuid(query);
    }
  }, [searchParams]);

  useEffect(() => {
    if (location.state) {
      setUuid(location.state?.uuid);
    }
  }, [location]);

  useEffect(() => {
    if (uuid) {
      seminarAssistanceIndexSortedByMember(setSa, uuid);
    }
  }, [uuid]);

  useEffect(() => {
    if (sa) {
      setSeminars(
        sa.map((obj) => {
          return {
            title:
              obj.seminar.title +
              (obj.seminar.serial_number
                ? ' #' + obj.seminar.serial_number
                : ''),
            date: obj.seminar.event_date_start_time,
            part1: obj.part1,
            part2: obj.part2,
            part3: obj.part3,
            part4: obj.part4,
            uuid: obj.uuid,
            seminarUuid: obj.seminar.uuid,
          };
        })
      );
      setLoading(false);
    }
  }, [sa]);

  // Hnadler
  const handleAuth = () => {
    navigate(various.general.login, { state: { previous: location.pathname } });
  };

  const handleCancel = (object) => {
    navigate(various.general.myPageSeminarCancelForm, {
      state: {
        title: object.title,
        seminar_uuid: object.seminar_uuid,
        previous: location.pathname,
        memberUuid: uuid,
        uuid: object.uuid,
      },
    });
  };

  // Contents
  const contentsTable = () => {
    if (seminars) {
      return (
        <>
          <table>
            <thead>
              <tr>
                <td style={{ width: '32px' }}>Index</td>
                <td style={{ minWidth: '160px' }}>セミナータイトル</td>
                <td style={{ minWidth: '160px' }}>開催日</td>
                <td style={{ minWidth: '80px' }}>第1部</td>
                <td style={{ minWidth: '80px' }}>第2部</td>
                <td style={{ minWidth: '80px' }}>第3部</td>
                <td style={{ minWidth: '160px' }}>キャンセル</td>
              </tr>
            </thead>
            <tbody>
              {seminars.map((seminar, i) => {
                return (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>
                      {seminar.title +
                        (seminar.serial_number
                          ? ' #' + seminar.serial_number
                          : '')}
                    </td>
                    <td>
                      {Clock({
                        timezone: TIMEZONE,
                        time: null,
                        string: seminar.date,
                      }).today +
                        Clock({
                          timezone: TIMEZONE,
                          time: null,
                          string: seminar.date,
                        }).hourMinutes}
                    </td>
                    <td>{seminar.part1 ? '参加' : '-'}</td>
                    <td>{seminar.part2 ? '参加' : '-'}</td>
                    <td>{seminar.part3 ? '参加' : '-'}</td>
                    <td>
                      {new Date(seminar.date) > new Date() ? (
                        <button
                          type="submit"
                          onClick={() =>
                            handleCancel({
                              title: seminar.title,
                              seminar_uuid: seminar.seminarUuid,
                              uuid: seminar.uuid,
                            })
                          }
                        >
                          キャンセルする
                        </button>
                      ) : (
                        <button
                          style={{
                            backgroundColor: 'grey',
                            cursor: 'not-allowed',
                          }}
                          disabled
                        >
                          キャンセルする
                        </button>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      );
    }
  };

  return (
    <>
      <div style={{ margin: '80px' }}>
        <OnOffByScroll place="General" />
      </div>
      <div className={classes.pageTitle}>
        現在申し込み済みのセミナー
        <BreadCrumbs crumbs={CRUMBS} />
        <p>注意事項</p>
        <ul>
          <li>
            参加されるコマの変更については、一度キャンセルの上、再度お申し込みください。
          </li>
          <li>
            クレジットカード決済でセミナーに申し込まれている方は、セミナーキャンセルと同時に返金手続きが行われ、ご契約のカード会社を通じて返金されます。
          </li>
          <li>
            セミナーキャンセルは、そのセミナーが開催される日の前日23:59まで可能です。
          </li>
          <li>キャンセルを繰り返す事はお控えください。</li>
        </ul>
      </div>
      <hr />
      {isAuth ? (
        <>
          {loading ? (
            <Spinner />
          ) : (
            <>
              <div className={classes.container}>{contentsTable()}</div>
              <Footer />
            </>
          )}
        </>
      ) : (
        <>
          <div>
            <span
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={handleAuth}
            >
              ログイン
            </span>
            してください。
          </div>
          <Footer />
        </>
      )}
    </>
  );
};
export default MyPageSeminarUpdate;
