import { makeStyles } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../auth';
import { useLocation, useNavigate } from 'react-router-dom';
import OnOffByScroll from '../Animation/onOffByScroll';
import { Spinner } from '../Spinner';
import various from '../General/various';
import {
  getStripeCustomerInfo,
  memberIndex,
  myPage,
} from '../Apis/apiOfManaged';
import BreadCrumbs from '../General/breadCrumbs';
import Footer from '../Footer/footer';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    margin: '0px 0px 80px 0px',
    textAlign: 'start',
    '& > p': {
      marginLeft: '8px',
    },
    '& > p, & > ul': {
      fontSize: '0.8rem',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'start',
    '& > div': {
      margin: '16px 8px 0px 8px',
      padding: '10px',
      width: '100%',
      textAlign: 'start',
      '& > ul': {
        listStyleType: 'disc',
        marginLeft: '20px',
        '& > li': {
          margin: '5px',
          fontSize: '0.9rem',
          '& > a, & > span': {
            textDecoration: 'underline',
            color: theme.palette.primary.main,
            cursor: 'pointer',
            fontWeight: 'bold',
          },
        },
      },
      '& > span': {
        fontSize: '0.9rem',
        '& > a': {
          textDecoration: 'underline',
          color: theme.palette.primary.main,
          cursor: 'pointer',
          fontWeight: 'bold',
        },
      },
    },
  },
}));

const SettlementAttention = () => {
  const { isAuth } = useContext(AuthContext);
  const [user, setUser] = useState();
  const [members, setMembers] = useState([]);
  const [member, setMember] = useState();
  const [stripeCustomer, setStripeCustomer] = useState();
  const [isInitial, setIsInitial] = useState(true);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();

  const CRUMBS = [
    { name: 'MyPage', path: '/mypage' },
    { name: '留意点', path: '/settlement_attention' },
  ];

  //   if (stripeCustomer) console.log('stripeCustomer:', stripeCustomer);

  useEffect(() => {
    myPage(setUser);
  }, []);

  useEffect(() => {
    if (user && isAuth) {
      memberIndex(setMembers);
      setLoading(false);
      getStripeCustomerInfo(setStripeCustomer, user.email);
    }
  }, [user, isAuth]);

  useEffect(() => {
    if (members.length > 0 && user) {
      members.forEach((m) => {
        if (m.email === user.email && m.user === user.id) {
          setMember(m);
        }
      });
    }
  }, [members, user]);

  useEffect(() => {
    if (stripeCustomer && setStripeCustomer?.by_stripe) {
      setIsInitial(false);
    }
  }, [stripeCustomer]);

  const handleAuth = () => {
    navigate(various.general.login, { state: { previous: location.pathname } });
  };

  const gotoStripe = () => {
    let url;
    if (member) {
      if (process.env.NODE_ENV === 'production') {
        if (process.env.REACT_APP_ENVIRONMENT === 'production') {
          url = various.stripe.production;
        } else if (process.env.REACT_APP_ENVIRONMENT === 'staging') {
          url = various.stripe.test;
        }
      } else if (process.env.NODE_ENV === 'development') {
        url = various.stripe.test;
      }
      window.location.href = url;
    }
  };
  const gotoPaymentView = (isInitial) => {
    navigate(various.general.paymentView, {
      state: {
        previous: location.pathname,
        customer_id: stripeCustomer?.stripe_customer_id,
        customer_email: stripeCustomer?.email,
        customer_type: stripeCustomer?.customer_type,
        user_email: user.email,
        isInitial: isInitial,
        stripeCustomer: stripeCustomer,
        member: member,
      },
    });
  };
  const seminarManaged = () => {
    if (member) {
      navigate(various.general.myPageSeminarUpdate, {
        state: { uuid: member.uuid },
      });
    }
  };

  const contents = () => {
    return (
      <div className={classes.container}>
        <div>
          <p>決済方法の登録に関する留意点</p>
          <ul>
            <li>
              決済方法が未登録の方は、<a href="/mypage">MyPage</a>
              内にリンクされている「
              <span onClick={() => gotoPaymentView(isInitial)}>
                決済手段の登録
              </span>
              」から登録することができます。
            </li>
            <li>
              決済方法が未登録の方がセミナーに申し込まれると、登録画面へ促すメッセージが出現し、そこのリンクからも登録することができます。
            </li>
            <li>
              刀禅会（以下、当会）では、2018年6月1日より施行された改正割賦販売法の求める所謂「クレジットカード情報の非保持化」に準拠しており、当会の管理する情報には会員のクレジットカード情報は存在しません。
            </li>
            <li>
              会員のクレジットカード情報は、当会の決済事業者である
              <a href="https://stripe.com/jp">Stripe</a>により管理されています。
            </li>
          </ul>
        </div>
        <div>
          <p>決済方法の使い方に関する留意点</p>
          <ul>
            <li>
              決済方法の登録情報は、<a href="/mypage">MyPage</a>
              内にリンクされている「
              <span onClick={gotoStripe}>決済方法の管理</span>
              」にて確認できます。
            </li>
            <li>
              登録しているクレジットカードの変更等は、上記「
              <span onClick={gotoStripe}>決済方法の管理</span>
              」より行なってください。
            </li>
            <li>
              定例会員の場合、月次の定例会費の決済に使用されます。なお、定例会費を現金にして支払うことはできません。
            </li>
            <li>
              セミナー参加費の決済方法を都度ご選択されたい場合は、
              <a href="/mypage">MyPage</a>
              内にの「会費等の決済（会員区分の選択）」セクションの「チェック（レ点）」を外してください。全ての決済をクレジットカードで行いたい場合はチェックを入れたままにしてください。なお、この変更は随時可能です。
            </li>
          </ul>
        </div>
        <div>
          <p>決済方法の変更に関する留意点</p>
          <ul>
            <li>
              すでに決済方法を登録されている方は、<a href="/mypage">MyPage</a>
              内にリンクされている「
              <span onClick={() => gotoPaymentView(isInitial)}>
                決済方法の変更
              </span>
              」から変更することができます。
            </li>
            <li>
              決済方法の変更は、会員区分の変更と等価です。変更画面では、定例会員・回数会員のいずれかをご選択される形で決定されます。
            </li>
            <li>
              従前通りの「現金決済」に戻すことも「
              <span onClick={() => gotoPaymentView(isInitial)}>
                決済方法の変更
              </span>
              」から行えます。
            </li>
            <li>
              決済方法が変更されると同時に会員区分も変更されます。その後再び会員区分の変更を行うには、以前の変更から半年を経過していないと変更を行えません。
            </li>
            <li>
              定例会員が現金決済に変更した場合、その変更した月の月末までは「現金決済の定例会員」という扱いになり、翌月々初に回数会員へと変更されます。
            </li>
          </ul>
        </div>
        <div>
          <p>決済方法の削除に関する留意点</p>
          <ul>
            <li>
              決済方法の削除を行うには、<a href="/mypage">MyPage</a>
              内にリンクされている「
              <span onClick={gotoStripe}>決済方法の管理</span>
              」から行うことができます。
            </li>
            <li>
              ただし、決済方法の削除を”随時”行うには回数会員でなくてはならず、定例会員が変更を行うには回数会員への変更が”完了”してから、それを行うことができます。
            </li>
          </ul>
        </div>
        <div>
          <p>返金に関する留意点</p>
          <ul>
            <li>月次の定例会費の返金には応じません。</li>
            <li>
              セミナー参加費の返金については、”セミナー参加のキャンセル”を通じて返金がなされます。セミナー参加のキャンセルは、
              <a href="/mypage">MyPage</a>内にリンクされている
              セミナー管理セクションの「
              <span onClick={seminarManaged}>Manage seminar</span>
              」からキャンセルをすることができ、自動で返金処理が実行されます。
            </li>
            <li>
              セミナー参加費の返金額は、セミナー参加費からセミナー申し込み時に発生した手数料を控除した金額で決定され、カード会社を通じて振り込まれます。
            </li>
            <li>
              セミナー参加費の返金時期については、皆様の契約しているカード会社の運営に完全に依存しており、当会において返金時期を把握することはできません。詳しい返金日時をお知りになりたい方は、直接カード会社へご連絡ください。
            </li>
            <li>
              なお、セミナーのキャンセルが可能な日時は、セミナー開催日前日23:59までであることにご注意ください。
            </li>
          </ul>
        </div>
        <div>
          <span>
            その他の関連事項については、「
            <a href="/mypage/constitution">会則</a>
            」をご確認ください。
          </span>
        </div>
      </div>
    );
  };

  return (
    <>
      <div style={{ margin: '80px' }}>
        <OnOffByScroll place="General" />
      </div>
      {isAuth ? (
        <>
          {loading ? (
            <Spinner />
          ) : (
            <>
              <div className={classes.pageTitle}>
                決済にかかる留意点
                <BreadCrumbs crumbs={CRUMBS} />
              </div>
              <hr />
              {contents()}
            </>
          )}
        </>
      ) : (
        <div>
          <span
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={handleAuth}
          >
            ログイン
          </span>
          してください。
        </div>
      )}
      <Footer />
    </>
  );
};
export default SettlementAttention;
